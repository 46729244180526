export default function GroupDistribBanner({
  title,
  firstDescription,
  secondDescription,
}) {
  return (
    <section className="group-distrib-section banner-section bg-white text-base-100">
      <div className="group-distrib-content banner-content flex flex-col items-center text-center gap-4">
        <h2 className="uppercase pb-[32px]">{title}</h2>
        <p className="max-w-[820px]">{firstDescription}</p>
        <p>{secondDescription}</p>
      </div>
    </section>
  );
}
