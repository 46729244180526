import React from "react";

export default function MainBtn({
  value,
  onClick,
  name,
  ariaLabel,
  red = true,
  className,
  icon,
}) {
  return (
    <button
      className={`${
        red ? "bg-primary btn-main" : "bg-white btn-main"
      } ${className}`}
      value={value}
      onClick={onClick}
      name={name}
      aria-label={ariaLabel || value}
    >
      {icon ? icon : null}
      {value}
    </button>
  );
}
