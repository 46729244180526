import React, { useLayoutEffect } from "react";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";

export default function PrivacyPolicy() {
  useLayoutEffect(() => {});

  return (
    <div className="flex container mx-auto pt-[120px]">
      <MetaTagsMaster robots={false} title="Politique de confidentialité" />
      <div className="h-full min-h-[1024px] md:mx-[64px] mx-[15px] py-[64px]">
        <h1 className="text-6xl md:text-7xl pb-[48px]">
          Politique de confidentialité
        </h1>
        <div style={{ paddingTop: "16px" }}>
          <div className="h-full min-h-[1024px] md:mx-[64px] mx-[15px] py-[64px]">
            <h2 className="md:text-4xl text-3xl pb-[24px]">
              1. Politique de confidentialité
            </h2>
            <p>
              Dernière mise à jour : 12/08/24
              <br />
              La présente politique de confidentialité régit la manière dont
              Pizza Cook collecte, utilise, conserve et divulgue les
              informations collectées auprès des utilisateurs du site web. Cette
              politique de confidentialité s'applique au Site et à tous les
              produits et services proposés par Pizza Cook.
            </p>

            <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              2. Informations personnelles
            </h2>
            <p>
              Nous pouvons collecter des informations personnelles auprès des
              Utilisateurs de différentes manières, notamment lorsque les
              Utilisateurs visitent notre Site, s'inscrivent sur le Site,
              passent une commande, s'abonnent à la newsletter, répondent à un
              formulaire, et en relation avec d'autres activités, services,
              fonctionnalités ou ressources que nous mettons à disposition sur
              notre Site. Les Utilisateurs peuvent être invités à indiquer leur
              nom, leur adresse e-mail, leur adresse postale, leur numéro de
              téléphone, leurs informations de carte de crédit et d'autres
              informations pertinentes. Les Utilisateurs peuvent toutefois
              visiter notre Site de manière anonyme. Nous collecterons des
              informations personnelles auprès des Utilisateurs uniquement s'ils
              nous les soumettent volontairement. Les Utilisateurs peuvent
              refuser de fournir des informations personnelles, sauf que cela
              peut les empêcher de participer à certaines activités liées au
              Site.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              3. Informations non personnelles
            </h3>
            <p>
              Nous pouvons collecter des informations non personnelles sur les
              Utilisateurs lorsqu'ils interagissent avec notre Site. Les
              informations non personnelles peuvent inclure le nom du
              navigateur, le type d'ordinateur et des informations techniques
              sur les moyens de connexion des Utilisateurs à notre Site, telles
              que le système d'exploitation et les fournisseurs de services
              Internet utilisés et d'autres informations similaires.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              4. Cookies du navigateur web
            </h3>
            <p>
              Notre Site peut utiliser des "cookies" pour améliorer l'expérience
              utilisateur. Le navigateur web de l'Utilisateur place des cookies
              sur leur disque dur à des fins de tenue de registres et parfois
              pour suivre les informations les concernant. L'Utilisateur peut
              choisir de configurer leur navigateur pour refuser les cookies, ou
              pour être alerté lorsque des cookies sont envoyés. Si tel est le
              cas, notez que certaines parties du Site peuvent ne pas
              fonctionner correctement.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              5. Comment nous utilisons les informations collectées
            </h3>
            <p>
              Pizza Cook peut collecter et utiliser les informations
              personnelles des Utilisateurs aux fins suivantes :
              <ul>
                <li>
                  • Pour améliorer le service client : Les informations que vous
                  fournissez nous aident à répondre à vos demandes de service
                  client et à vos besoins de manière plus efficace.
                </li>
                <li>
                  • Pour personnaliser l'expérience utilisateur : Nous pouvons
                  utiliser les informations en notre possession pour comprendre
                  comment nos Utilisateurs utilisent les services et les
                  ressources proposés sur notre Site.
                </li>
                <li>
                  • Pour traiter les paiements : Nous pouvons utiliser les
                  informations que les Utilisateurs fournissent sur eux-mêmes
                  lorsqu'ils passent une commande uniquement pour fournir un
                  service à cette commande. Nous ne partageons pas ces
                  informations avec des tiers, sauf dans la mesure nécessaire
                  pour fournir le service.
                </li>
                <li>
                  • Pour envoyer des e-mails périodiques : Nous pouvons utiliser
                  l'adresse e-mail des Utilisateurs pour leur envoyer des
                  informations et des mises à jour concernant leur commande. Il
                  peut également être utilisé pour répondre à leurs demandes,
                  questions et/ou autres demandes. Si les Utilisateurs décident
                  de s'inscrire à notre liste de diffusion, ils recevront des
                  e-mails qui peuvent inclure des nouvelles de l'entreprise, des
                  mises à jour, des informations sur les produits ou services
                  liés, etc. Si à tout moment l'Utilisateur souhaite se
                  désinscrire de la réception de futurs e-mails, nous incluons
                  des instructions de désinscription détaillées au bas de chaque
                  e-mail.
                </li>
              </ul>
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              6. Protection des informations
            </h3>
            <p>
              Nous adoptons des pratiques appropriées de collecte, de stockage
              et de traitement des données et des mesures de sécurité pour
              protéger contre tout accès non autorisé, altération, divulgation
              ou destruction de vos informations personnelles, nom
              d'utilisateur, mot de passe, informations de transaction et
              données stockées sur notre Site.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              7. Partage de vos informations personnelles
            </h3>
            <p>
              Nous ne vendons, n'échangeons ni ne louons vos informations
              personnelles à des tiers. Nous pouvons partager des informations
              démographiques agrégées génériques non liées à des informations
              personnelles concernant les visiteurs et les utilisateurs avec nos
              partenaires commerciaux, partenaires de confiance et annonceurs à
              des fins décrites ci-dessus.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              8. Modifications de cette politique de confidentialité
            </h3>
            <p>
              Pizza Cook se réserve le droit de mettre à jour cette politique de
              confidentialité à tout moment. Nous vous encourageons à consulter
              fréquemment cette page pour rester informé de la manière dont nous
              aidons à protéger les informations personnelles que nous
              collectons. Vous reconnaissez et acceptez qu'il est de votre
              responsabilité de consulter périodiquement cette politique de
              confidentialité et de prendre connaissance des modifications.
            </p>
            <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
              9. Votre acceptation de ces termes
            </h3>
            <p>
              En utilisant ce Site, vous signifiez votre acceptation de cette
              politique. Si vous n'êtes pas d'accord avec cette politique,
              veuillez ne pas utiliser notre Site. Votre utilisation continue du
              Site après la publication de modifications apportées à cette
              politique sera considérée comme votre acceptation de ces
              modifications.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
