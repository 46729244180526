import React, { useLayoutEffect } from "react";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";

export default function LegalTerms() {
  useLayoutEffect(() => {});

  return (
    <div className="flex container mx-auto pt-[120px]">
      <MetaTagsMaster robots={false} title="Mentions Légales" />
      <div className="h-full min-h-[1024px] md:mx-[64px] mx-[15px] py-[64px]">
        <h1 className="uppercase text-6xl md:text-7xl pb-[48px]">
          Mentions Légales
        </h1>

        <h2 className="md:text-4xl text-3xl pb-[24px]">
          1. Informations légales
        </h2>
        <p>
          Éditeur du site :
          <p>
            <strong>Pizza Cook</strong>
            <br />
            <strong>Siège social :</strong> 16 Rue Emmanuel Voisin
            Saint-Sylvain-d'Anjou, 49112 VERRIERES-EN-ANJOU, France <br />
            <strong>Numéro de téléphone :</strong> 0685442200 <br />
            <strong>Numéro SIRET :</strong> 89048390200015 <br />
            <strong>Contact :</strong> pizzacook49@gmail.com
          </p>
        </p>

        <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
          2. Créateur du site
        </h2>
        <p>
          RouleMarcel – EURL 82489759900034 – 42 rue du Général Lizé – 49100
          ANGERS
        </p>

        <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
          3. Hébergement
        </h2>
        <p>Le site est hébergé par : Titan Datacenters France</p>
        <p>Adresse : 1210 Chem. de la Plaine, 06250 Mougins</p>

        <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
          4. Propriété intellectuelle
        </h2>
        <p>
          Le Site Internet de la société Pizza Cook, dont la présentation
          graphique et technologique est l’œuvre originale de la société
          RouleMarcel, ainsi que les éléments qui le composent, sont protégés
          par les Droits de Propriété intellectuelle. Il est interdit, hors
          autorisation expresse et écrite donnée par la société RouleMarcel au
          tiers qui en ferait la demande, de collecter, émettre, diffuser,
          modifier, publier, même en partie, sous quelque forme que ce soit, les
          données, les textes, les images, la présentation, l’organisation, la
          technologie utilisée, du Site Internet. Elle interdit notamment, sauf
          autorisation expresse et écrite, tout téléchargement ou copie de
          photo, image ou texte. La Société RouleMarcel, sans préjudice des
          poursuites pouvant être engagées par le créateur du Site Internet
          RouleMarcel concernant la violation des Droits qu’il détient sur ses
          propres créations, se réserve la possibilité d’engager des poursuites
          civiles et/ou pénales à quiconque porterait atteinte à ses Droits au
          titre de la propriété intellectuelle ou se rendrait coupable de
          contrefaçon.
        </p>
        <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
          5. Décharge de responsabilité
        </h2>
        <p>
          Les Sociétés RouleMarcel et Pizza Cook s’efforcent d’actualiser
          régulièrement les informations contenues dans le site et veille à leur
          exactitude, aucune responsabilité de ces sociétés ne pourrait être
          toutefois recherchée pour erreur ou omission dans les informations
          présentes sur le Site Internet.
        </p>
        <h2 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
          6. Politique de confidentialité
        </h2>
        <div className="ml-[64px]">
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            1. Informations collectées
          </h3>
          <p>
            Les informations contenues dans le formulaire et lors de votre
            inscription/commande sont recueillies et conservées pour une durée
            de minimum 3 ans pour les demandes d’informations et pendant la
            durée légale imposée par la loi pour les commandes.
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            2. Utilisation des informations
          </h3>
          <p>
            Toute les informations recueillies visent à satisfaire votre demande
            ou votre commande, éventuellement à vous proposer un service ou une
            offre pouvant découler de celle-ci.
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            3. Divulgation à des tiers
          </h3>
          <p>
            Les informations collectées ne seront pas revendues à des tiers en
            dehors des obligations découlant de la commande ou quand la loi nous
            y contraint.
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            4. Protection des informations
          </h3>
          <p>
            Les mesures de sécurité nécessaires pour préserver au maximum la
            sécurité de vos informations personnelles sont mises en œuvre.
            Est-ce que nous utilisons des cookies ? Oui, pour des raisons
            techniques et pour améliorer l’ergonomie et l’expérience
            utilisateur.
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            5. Retrait ou effacement des informations
          </h3>
          <p>
            Si vous souhaitez que les informations vous concernant soient
            effacées de nos bases de données, dans le cas où nous n’aurions pas
            d’obligation légale à les conserver, merci d’utiliser ci-dessous.
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            6. Bloctel
          </h3>
          <p>
            Nous vous informons de l’existence de la liste d’opposition au
            démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous
            inscrire (www.bloctel.gouv.fr).
          </p>
          <h3 className="md:text-4xl text-3xl pb-[24px] mt-[48px]">
            7. Consentement
          </h3>
          <p>
            En utilisant notre site, vous consentez à notre politique de
            confidentialité.
          </p>
        </div>
      </div>
    </div>
  );
}
