import React, { Suspense, useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { ToggleContext } from "../utils/Providers";
import CookiesBanner from "../CookiesBanner";

export default function MainLayout() {
  const { toggle } = useContext(ToggleContext);

  return (
    <React.Fragment>
      <Navbar />
      <Suspense
        fallback={
          <div className="w-full h-[80vh] flex justify-center items-center">
            <div className="loader" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      <CookiesBanner />
      <Footer toggle={toggle} />
    </React.Fragment>
  );
}
