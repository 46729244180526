import { motion } from "framer-motion";
import { useContext } from "react";
import Pizza404 from "../../assets/img/Pizza-404.png";
import Pizza404Webp from "../../assets/img/Pizza-404.webp";
import MushroomGrey from "../../assets/img/mushroom-grey.png";
import MushroomGreyWebp from "../../assets/img/mushroom-grey.webp";
import PizzaLettersGrey from "../../assets/img/pizza-letters-grey.png";
import PizzaLettersGreyWebp from "../../assets/img/pizza-letters-grey.webp";
import ScooterGrey from "../../assets/img/scooter-grey.png";
import ScooterGreyWebp from "../../assets/img/scooter-grey.webp";
import WheatGrey from "../../assets/img/wheat-grey.png";
import WheatGreyWebp from "../../assets/img/wheat-grey.webp";
import MainBtn from "../../components/buttons/MainBtn";
import { WidthContext } from "../../components/utils/Providers";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";

export default function NotFound() {
  const { width } = useContext(WidthContext);

  return (
    <div className="not-found overflow-hidden">
      <MetaTagsMaster
        title="404"
        description="Oops ! Votre page a été mangée ou n'existe plus au menu"
        robots={false}
      />
      <picture className="wheat-grey-404">
        <source srcSet={WheatGreyWebp} type="image/webp" />
        <source srcSet={WheatGrey} type="image/png" />
        <img src={WheatGrey} alt="" />
      </picture>
      <picture className="pizza-letters-grey-404">
        <source srcSet={PizzaLettersGreyWebp} type="image/webp" />
        <source srcSet={PizzaLettersGrey} type="image/png" />
        <img src={PizzaLettersGrey} alt="" />
      </picture>
      <div className="flex flex-col items-center justify-center main-404">
        <h1 className="flex items-center justify-center title-404">
          <span className="four-span">4</span>
          <motion.picture
            initial={{}}
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 10,
              ease: "linear",
            }}
          >
            <source srcSet={Pizza404Webp} type="image/webp" />
            <source srcSet={Pizza404} type="image/png" />
            <img
              src={Pizza404}
              alt="Une pizza qui a été mangée et qui tourne"
            />
          </motion.picture>
          <span className="four-span">4</span>
        </h1>
        <p className="text-base-100 not-found-content text-center">
          Oops ! Votre page a été mangée ou n'existe plus au menu
        </p>
        <MainBtn
          value="Retour à l'accueil"
          onClick={() => (window.location.href = "/")}
        />
      </div>
      <picture className="mushroom-grey-404">
        <source srcSet={MushroomGreyWebp} type="image/webp" />
        <source srcSet={MushroomGrey} type="image/png" />
        <img src={MushroomGrey} alt="" className="mushroom-grey-404" />
      </picture>
      <motion.picture
        initial={{
          x: width >= 768 ? -600 : -250,
          opacity: 0,
          rotate: -30,
        }}
        whileInView={{
          x: 0,
          opacity: 1,
          rotate: [0, -30, -30, 30, 0],
        }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{
          duration: 3.5,
          ease: "backInOut",
        }}
        className="scooter-grey-404"
      >
        <source srcSet={ScooterGreyWebp} type="image/webp" />
        <source srcSet={ScooterGrey} type="image/png" />
        <img
          src={ScooterGrey}
          alt=""
          loading="lazy"
          className="scooter-grey-404"
        />
      </motion.picture>
    </div>
  );
}
