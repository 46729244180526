import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import EventStockBanner from "../../components/sections/banners/EventStockBanner";
import GroupDistribBanner from "../../components/sections/banners/GroupDistribBanner";
import ImgBanner from "../../components/sections/banners/ImgBanner";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function CompanyDelivery() {
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleClick = (ev) => {
    if (ev.target.name === "menu") {
      navigate("/notre-carte");
    }
    if (ev.target.name === "request") {
      navigate("/contact");
    }
  };

  useEffect(() => {
    setColor("white");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Groupes & entreprises"
        description="Réunion de famille, repas entre amis, fêtes d'entreprise, etc. Pizza Cook s'adapte à vos envies et vous propose la livraison."
        keywords="livraison, groupes, entreprises, repas, pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="Groupes & entreprises"
        description="Vous souhaitez commander des pizzas un midi entre collègues ? Vous voulez faire une commande groupée et vous êtes situés dans un rayon de 15 kilomètres autour d’Angers ? Pizza Cook vous propose un service de commande et livraison adapté (sous conditions). "
        option="group"
        width={width}
      />
      <GroupDistribBanner
        title="Commandez en groupe et bénéficiez de la livraison "
        firstDescription="Notre pizzeria de Saint-Sylvain-d’Anjou propose aux entreprises et salariés un service de livraison* le midi, sur un rayon de 15 kilomètres autour d’Angers. (sous conditions de volume et de disponibilité)"
        secondDescription="Nous vous garantissons des pizzas chaudes, pré-coupées et livrées directement au sein de votre entreprise."
      />
      <PizzaCarrouselSection onClick={handleClick} />
      <EventStockBanner
        title="Vous organisez un événement et souhaitez faire appel aux services de Pizza Cook ? "
        description="Nous réalisons régulièrement des prestations pour des événements avec public. Nous serions ravis de vous accompagner et de participer à votre prochain événement."
        secondDescription={
          "Merci d’effectuer une demande en ligne, nous prendrons rapidement contact avec vous pour plus d'informations."
        }
        value="Faire une demande en ligne"
        name="request"
        onClick={handleClick}
      />
      <ImgBanner option="group" />
      <PizzeriasSection width={width} />
    </>
  );
}
