import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PizzaCardAnimation from "./PizzaCardAnimation";

export default function PizzaCard({ pizza, display, onClick, width }) {
  const [size, setSize] = useState("normale");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return display === "mosaic" ? (
    <div
      className="pizza-card-mosaic flex flex-col items-center bg-white py-6 gap-4 rounded-xl cursor-pointer"
      onClick={onClick}
    >
      <picture className="min-h-[130px]">
        <source
          srcSet={pizza.imageWebp}
          type="image/webp"
          className="pizza-card-image"
        />
        <source
          srcSet={pizza.imagePng}
          type="image/png"
          className="pizza-card-image"
        />
        <img
          src={pizza.imagePng}
          alt={`Pizza ${pizza.name}`}
          width={130}
          loading="lazy"
          className="pizza-card-image"
        />
      </picture>
      <h3 className="pizza-card-title text-base-100 uppercase leading-[1em]">
        {pizza.name}
      </h3>
      <p className="pizza-card-price text-primary text-center">
        {pizza.priceMin}€
      </p>
    </div>
  ) : display === "list" ? (
    <div
      className="pizza-card-list flex gap-4 items-center bg-white rounded-xl py-6 cursor-pointer"
      onClick={onClick}
    >
      <picture>
        <source
          srcSet={pizza.imageWebp}
          type="image/webp"
          className="pizza-card-image"
        />
        <source
          srcSet={pizza.imagePng}
          type="image/png"
          className="pizza-card-image"
        />
        <img
          src={pizza.imagePng}
          alt={`Pizza ${pizza.name}`}
          width={130}
          loading="lazy"
          className="pizza-card-image"
        />
      </picture>
      <div className="pizza-list-content grid w-full">
        <h3 className="pizza-card-title text-base-100 uppercase leading-[1em]">
          {pizza.name}
        </h3>
        <p className="pizza-card-price text-primary place-self-end">
          {pizza.priceMin}€
        </p>
        <p className="pizza-card-description text-base-100">
          {pizza.description}
        </p>
      </div>
    </div>
  ) : display === "details" && width < 768 ? (
    <>
      <div className="pizza-card-details-container bg-white">
        <div className="pizza-card-details flex flex-col text-white md:text-base-100 bg-base-100 md:bg-secondary">
          <div className="flex justify-between">
            <button onClick={handleBack} className="flex items-start">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0584 5.93881C15.1982 6.07816 15.3092 6.24375 15.3849 6.42608C15.4606 6.6084 15.4996 6.80389 15.4996 7.00131C15.4996 7.19873 15.4606 7.3942 15.3849 7.57653C15.3092 7.75886 15.1982 7.92445 15.0584 8.06381L8.62212 14.5001L26.9971 14.5001C27.3949 14.5001 27.7765 14.6581 28.0578 14.9394C28.3391 15.2207 28.4971 15.6022 28.4971 16.0001C28.4971 16.3979 28.3391 16.7794 28.0578 17.0607C27.7765 17.342 27.3949 17.5001 26.9971 17.5001L8.62212 17.5001L15.0584 23.9388C15.3402 24.2206 15.4985 24.6028 15.4985 25.0013C15.4985 25.3998 15.3402 25.782 15.0584 26.0638C14.7766 26.3456 14.3944 26.5039 13.9959 26.5039C13.5974 26.5039 13.2152 26.3456 12.9334 26.0638L3.93337 17.0638C3.79353 16.9245 3.68257 16.7589 3.60687 16.5765C3.53116 16.3942 3.49219 16.1987 3.49219 16.0013C3.49219 15.8039 3.53116 15.6084 3.60687 15.4261C3.68257 15.2437 3.79353 15.0782 3.93337 14.9388L12.9334 5.93881C13.0727 5.79897 13.2383 5.68801 13.4206 5.6123C13.603 5.53659 13.7984 5.49762 13.9959 5.49762C14.1933 5.49762 14.3888 5.53659 14.5711 5.6123C14.7534 5.68801 14.919 5.79897 15.0584 5.93881Z"
                  fill={width < 768 ? "white" : "#1D1D1B"}
                />
              </svg>
            </button>
            <motion.picture
              initial={{ scale: 0, opacity: 0, rotate: 0 }}
              // animate={{ scale: 1, opacity: 1, rotate: 360 }}
              whileInView={{ scale: 1, opacity: 1, rotate: 360 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 1.5 }}
            >
              <source
                srcSet={pizza.imageWebp}
                type="image/webp"
                className="pizza-card-image"
              />
              <source
                srcSet={pizza.imagePng}
                type="image/png"
                className="pizza-card-image"
              />
              <img
                src={pizza.imagePng}
                alt={`Pizza ${pizza.name}`}
                width={130}
                loading="lazy"
                className="pizza-card-image"
              />
            </motion.picture>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onClick}
            >
              <path
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                stroke={width < 768 ? "white" : "#1D1D1B"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 10.666H16.0133V10.6793H16V10.666Z"
                stroke={width < 768 ? "white" : "#1D1D1B"}
                strokeWidth="3"
                strokeLinejoin="round"
              />
              <path
                d="M16 16V21.3333"
                stroke={width < 768 ? "white" : "#1D1D1B"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="pizza-card-details-content flex flex-col gap-8">
            <h1 className="pizza-card-title uppercase text-center">
              {pizza.name}
            </h1>
            <div
              className={
                pizza.category === "adulte" ? "grid grid-cols-2 gap-2" : ""
              }
            >
              <button
                className={`flex flex-col place-self-center w-full ${
                  size === "normale"
                    ? "bg-primary" + " text-white"
                    : "bg-white text-base-100"
                } rounded-lg py-2 cursor-pointer`}
                onClick={() => setSize("normale")}
              >
                <p className="place-self-center">Normale</p>
                <p className="place-self-center">(29cm)</p>
              </button>
              {pizza.category === "adulte" && (
                <button
                  className={`flex flex-col place-self-center w-full ${
                    size === "maxi"
                      ? "bg-primary" + " text-white"
                      : "bg-white text-base-100"
                  } rounded-lg py-2 cursor-pointer`}
                  onClick={() => setSize("maxi")}
                >
                  <p className="place-self-center">Maxi</p>
                  <p className="place-self-center">(40cm)</p>
                </button>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="pizza-card-ingredients">Ingrédients</h2>
              <p className="pizza-card-description">{pizza.description}</p>
            </div>
            <p className="pizza-card-price price-details text-center">
              {size === "normale" ? pizza.priceMin : pizza.priceMax}€
            </p>
            <a
              href="tel:+33241954053"
              className={"!text-white bg-red btn-main flex items-center justify-center"}
            >
              <svg
                width="32"
                height="30"
                viewBox="0 0 45 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-[16px]"
              >
                <path
                  d="M35.3747 27.9769L30.718 27.4452C30.1705 27.3809 29.6155 27.4415 29.0947 27.6225C28.574 27.8035 28.101 28.1002 27.7114 28.4902L24.338 31.8636C19.1336 29.2166 14.9033 24.9864 12.2564 19.7819L15.648 16.3902C16.4364 15.6019 16.8214 14.5019 16.693 13.3836L16.1614 8.76356C16.0574 7.86921 15.6282 7.04432 14.9555 6.44594C14.2827 5.84756 13.4134 5.51749 12.513 5.51856H9.34137C7.2697 5.51856 5.54637 7.24189 5.6747 9.31356C6.64637 24.9702 19.168 37.4736 34.8064 38.4452C36.878 38.5736 38.6014 36.8502 38.6014 34.7786V31.6069C38.6197 29.7552 37.2264 28.1969 35.3747 27.9769Z"
                  fill="#FFF"
                />
              </svg>
              Passer commande
            </a>
          </div>
        </div>
      </div>
    </>
  ) : display === "details" && width >= 768 ? (
    <PizzaCardAnimation
      pizza={pizza}
      onClick={handleBack}
      width={width}
      onModal={onClick}
    />
  ) : (
    <div
      className="pizza-card-landing flex flex-col items-center bg-white cursor-pointer"
      onClick={onClick}
    >
      <picture className="pizza-img-carousel">
        <source
          srcSet={pizza.imageWebp}
          type="image/webp"
          className="pizza-card-image"
        />
        <source
          srcSet={pizza.imagePng}
          type="image/png"
          className="pizza-card-image"
        />
        <img
          src={pizza.imagePng}
          alt={`Pizza ${pizza.name}`}
          loading="lazy"
          className="pizza-card-image"
        />
      </picture>
      <h3 className="pizza-card-title text-base-100 uppercase leading-[1em] text-center">
        {pizza.name}
      </h3>
      <p className="pizza-card-price text-primary text-center">
        {pizza.priceMin}€
      </p>
    </div>
  );
}