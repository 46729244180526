import MainBtn from "../../buttons/MainBtn";

export default function EventStockBanner({
  onClick,
  value,
  title,
  description,
  name,
  secondDescription,
}) {
  return (
    <section className="event-stock-section banner-section bg-base-100 text-white">
      <div className="event-stock-content banner-content flex flex-col text-center items-center">
        <h2 className="uppercase max-w-[1024px]">{title}</h2>
        <p className="max-w-[840px]">{description}</p>
        {secondDescription && (
          <p className="max-w-[820px]">{secondDescription}</p>
        )}
        <MainBtn value={value} onClick={onClick} name={name} />
      </div>
    </section>
  );
}
